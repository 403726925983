<template>
  <main>
    <div class="main-div">
      <div class="contact-details">
        <span class="contact-us-title"> Contact Us </span>
        <div class="contactus-divider"></div>
        <div class="caption">
          <p class="caption-1">
            Need a hand? Or a high five? <br />
            Here's how to reach us.
          </p>
          <div>
            <span class="span1">Phone</span><br />
            <span class="span2"><a href="tel:+27 67 636 5345">+27 67 636 5345</a></span>
          </div>
          <div>
            <span class="span1">General Enquiry</span><br />
            <a class="footer-item" href="mailto:">info@matriclive.com</a>
          </div>
        </div>
        <div class="icons">
          <a href="https://www.facebook.com/matriclive" target="_blank" class="fa fa-facebook"></a>
          <a href="https://www.instagram.com/matriclive/" target="_blank" class="fa fa-instagram"></a>
          <a href="https://www.linkedin.com/company/matriclive" target="_blank" class="fa fa-linkedin"></a>
        </div>
      </div>
      <div class="photo">
          <img src="@/assets/impactpage/Group 2076.svg" alt="home">
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "ContactUs",
};
</script>

<style scoped>
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");
main {
  background-image: url("../../assets/careerspage/Desktop - 1.svg");
  background-size: auto;
  text-align: initial;
  
}

.main-div {
  margin: 10% 8%;
  display: flex;
  justify-content: space-evenly;
}
.contact-us-title {
  font-size: xxx-large;
  font-weight: bold;
}

.contactus-divider {
  width: 25px;
  height: 3px;
  background: #23201e;
  margin-top: 30px;
  margin-left: 0;
  margin-bottom: 15px;
}

.caption .caption-1 {
  margin: 2% 0%;
  /* color: #f47622; */
  color: mediumpurple;
}

.caption {
  margin-left: 2%;
}

.caption > div {
  margin-top: 2%;
}

a {
    /* color: #f47622; */
  color: mediumpurple;
}

.photo > img {
    width: 30em;
}

div > .span1 {
    font-weight: 700;
}

div > .span2 {
    /* color: #f47622; */
    color: mediumpurple;
}

.fa {
  padding: 15px;
  font-size: 16px;
  width: 45px;
  text-align: center;
  text-decoration: none;
  margin: 5px 4px;
  border-radius: 50%;
}

.fa:hover {
  opacity: 0.7;
}

.fa-facebook {
  /* background: #f47622; */
  background: #3C064F;
  color: white;
}

.fa-instagram {
  /* background: #f47622; */
  background: #3C064F;
  color: white;
}

.fa-linkedin {
  /* background: #f47622; */
  background: #3C064F;
  color: white;
}

.icons {
  margin: 15% 0%;
}

@media screen and (min-width: 768px) and (max-width: 1028px){
  .photo > img {
    width: 18em;
  }
}

@media screen and (max-width: 655px){
  .photo > img {
    width: 18em;
  }
}

@media screen and (max-width: 768px){
  .main-div {
    margin: 10% 8%;
    display: block;
    text-align: center;
  }

  .contactus-divider {
    
    margin-left: 50%;
    margin-right: 50%;
    
}
}


</style>